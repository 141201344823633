import React from 'react';
import BraftEditor from 'braft-editor';
import axios from 'axios';
import Loader from './component/Loader';

export default class App extends React.PureComponent {

	state = {
		html: '',
		isProcessing: true,
	};

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const field = urlParams.get('field');
		const id = urlParams.get('id');
		if (field === 'wifi' || field === 'travelInfo' || field === 'floorPlan') {
			this.fetchHtml(id, field);
		}
	}

	fetchHtml = async (id, field) => {
		const query = `
			{
			  event(id: "${id}") {
			    ${field}
			  }
			}
		`;

		try {
			const res = await axios.post('/gql', { query });
			if (res.data.data.event) {
				const html = BraftEditor.createEditorState(JSON.parse(res.data.data.event[`${field}`])).toHTML();
				this.setState({
					html,
					isProcessing: false,
				});
			}
		} catch (e) {

		}
	};


	render() {
		const { isProcessing, html } = this.state;
		if (isProcessing) {
			return (
				<div style={{ marginTop: '20px' }}>
					<Loader/>
				</div>
			);
		} else {
			return (
				<div
					dangerouslySetInnerHTML={{ __html: html }}
					className="pure-html"
				/>
			);
		}
	}
};
